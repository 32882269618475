<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        <h1>{{ $t('Create Page section') }}</h1>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.pages.sections.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <from-request @submit="handleSave" :errors="errors"/>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>

import PageSection from "@/models/admin/PageSection";
import FromRequest from "@/views/admin/page-sections/FromRequest";
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";

export default {
    name: "faq-sections-add",
    components: {AdminDashboardLayout, FromRequest},
    data() {
        return {
            errors: {}
        }
    },
    methods: {
        handleSave(section) {
            (new PageSection(section))
                .save()
                .then(() => {
                    this.$store.commit('showSnackBar', this.$t('messages.success'))
                    this.$router.push({name: 'admin.pages.sections.index'})
                })
                .catch(({response}) => {
                    if(response.status === 422) {
                        this.errors = response.data.errors
                    }
                })
        },
    },
}
</script>

<style lang="scss">
</style>
